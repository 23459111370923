<template>
  <v-row>

    <v-col cols="12">
      <demo-simple-table-basic></demo-simple-table-basic>
    </v-col>
    <v-card-text style="height: 100px; position: relative">
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          bottom
          right
          fab
          @click="kurumDialogShow"
        >
          <v-icon>{{mdiLoupe}}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-text>
    <v-dialog
      v-model="dialog.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Yeni Kurum</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Kurum Adı"
                  required
                  v-model="kurum.kurum_adi"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Kurum Açıklaması"
                  v-model="kurum.kurum_aciklama"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Yetkili Adı Soyadı"
                  v-model="kurum.kurum_adi_soyadi"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col 
                cols="12"
                sm="6"
                md="6">
                <v-text-field
                  v-model="kurum.kurum_telefon_numarasi"
                  label="Yetkili Telefon Numarası"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.show = false"
          >
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="kurumKaydet"
          >
            Oluştur
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import axios from 'axios'
import { reactive } from '@vue/composition-api'
import { mdiLoupe,mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import DemoSimpleTableBasic from '../simple-table/demos/DemoSimpleTableBasic.vue'

// demos
import DashboardCongratulationJohn from './DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
import DashboardCardDepositAndWithdraw from './DashboardCardDepositAndWithdraw.vue'
import DashboardCardSalesByCountries from './DashboardCardSalesByCountries.vue'
import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'
import DashboardDatatable from './DashboardDatatable.vue'



export default {
  components: {
    DemoSimpleTableBasic,
    StatisticsCardVertical,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardDepositAndWithdraw,
    DashboardCardSalesByCountries,
    DashboardWeeklyOverview,
    DashboardDatatable,
  },
  setup() {

    const totalProfit = {
      statTitle: 'Total Profit',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    const totalSales = {
      statTitle: 'Refunds',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Past Month',
      statistics: '$78',
      change: '-15%',
    }

    // vertical card options
    const newProject = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const salesQueries = {
      statTitle: 'Sales Quries',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Last week',
      statistics: '15',
      change: '-18%',
    }
    
    const dialog = reactive({
      show: false,
    })
    function kurumDialogShow() {
      this.dialog.show=true
    }

    const kurum = reactive({})
    function kurumKaydet() {
      console.log(kurum)

      axios.post('https://ziyaretapp-637e.restdb.io/rest/ziyaretci', kurum, 
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          'cache-control': 'no-cache',
          'x-apikey': 'f7fd69da75b2033e01ce43e2e1fab67ec0d5e'
        }
      })
      .then(function (response) {
        console.log (response);
      })
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
      mdiLoupe,
      dialog,
      kurum,
      kurumKaydet,
      kurumDialogShow
    }
  },
}
</script>
